import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Layout, Header } from 'components';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;

  > header,
  > iframe {
    position: absolute;
    width: 100%;
  }

  .iframe {
    height: 100%;
    border: none;
    margin: 0;
  }
`;

const ExternalLink = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { brand, url, topOffset } = frontmatter;
  return (
    <Layout>
      <MainWrapper>
        <iframe
          className="iframe"
          title="banners"
          src={url}
          style={{ top: topOffset, height: `calc(100% - ${topOffset}px)` }}
        />
        <Header brandLogo={brand} />
      </MainWrapper>
    </Layout>
  );
};

export default ExternalLink;

ExternalLink.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
};

export const externalLinkQuery = graphql`
  query externalLinkQueryBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        brand
        title
        url
        topOffset
      }
      fields {
        slug
        template
      }
    }
  }
`;
